@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper

.Button
  padding: 0 10px

  &[aria-expanded="true"]
    .Icon
      transform: scaleY(-1)

.Icon
  color: $black-transparent-40
  transform: scaleY(1)
  transition: transform 100ms linear

  @include dark
    color: $white-transparent-40

.Content
  padding: 20px
  user-select: none

  address
    font-style: normal

.Address
  font-size: 14px
  line-height: 1

.WalletIcon
  overflow: hidden
  position: relative

  &::after
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    box-shadow: inset 0px 0px 0px 1px $black-transparent-20

.WalletIconButton
  border-radius: 4px

  &::after
    border-radius: 4px

  canvas
    border-radius: 4px

.WalletIconButtonCompact
  border-radius: 10px

  &::after
    border-radius: 10px

  canvas
    border-radius: 10px

.WalletIconDropdown
  border-radius: 10px

  &::after
    border-radius: 10px

  canvas
    border-radius: 10px

.CopyIcon
  cursor: pointer
  opacity: 20%
  transition: opacity 200ms linear

  &:hover
    opacity: 40%

.Footer
  border-top: 1px solid
  padding: 8px

.SignOutButton
  width: 100%
  padding: 12px
  border-radius: 6px
  transition: background-color 200ms linear
  cursor: pointer
  color: #fa2525
  line-height: 20px

  path
    fill: #fa2525

  &:hover
    background-color: $red-transparent-10

// ------------------- THEME COLORS ------------------- //

.Wrapper
  &.Light
    .WalletIcon
      border-color: $black-transparent-20

    .Footer
      border-color: $dark-transparent-10

  &.Dark
    .WalletIcon
      border-color: $white-transparent-20

    .Footer
      border-color: $white-transparent-10

    .CopyIcon
      path
        fill: $white
