@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  background: $white
  border: 1px solid $black-transparent-10
  border-radius: 16px
  display: flex
  flex-direction: column
  gap: 6px
  padding: 12px

  @include dark
    background: $white-transparent-5
    border-color: transparent

.TypeLabel
  align-items: center
  border-radius: 4px
  display: inline-flex
  font-weight: 500
  font-size: 14px
  gap: 4px
  line-height: 20px
  padding: 2px 4px

  &_delist
    background: rgba(112, 110, 122, 0.08)
    color: #A09EA6

  &_list
    background: rgba(164, 118, 200, 0.08)
    color: #A476C8

  &_mint
    background: rgba(227, 152, 64, 0.08)
    color: #E39840

  &_sale
    background: rgba(10, 192, 127, 0.08)
    color: #38BB8C

  &_transfer
    background: rgba(86, 129, 227, 0.08)
    color: #5681E3

.Address
  align-items: center
  font-weight: 400
  font-size: 14px
  line-height: 20px

.Price
  font-weight: 600
  font-size: 14px
  line-height: 20px

.Time
  align-items: center
  color: $black-transparent-60
  font-weight: 400
  font-size: 14px
  line-height: 20px
  gap: 4px

  @include dark
    color: $white-transparent-60
