@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: relative
  // width: 100%
  // height: 100%
  border-radius: inherit
  user-select: none
  overflow: hidden

  img
    pointer-events: none
    border-top-left-radius: inherit
    border-top-right-radius: inherit

  video
    border-top-left-radius: inherit
    border-top-right-radius: inherit
    object-fit: cover

  &:hover
    [data-class='badges']
      opacity: 1
      visibility: visible

    [data-class='controls']
      opacity: 1
      visibility: visible

.Controls
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  z-index: 2
  padding: 12px
  visibility: hidden
  opacity: 0
  transition: opacity 200ms linear, transform 200ms linear

  &.isPaused
    opacity: 1
    visibility: visible

.Play
  position: absolute
  left: 12px
  bottom: 12px
  z-index: 2

.Preview
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  border-top-left-radius: 12px
  border-top-right-radius: 12px
  z-index: 1

  img
    object-fit: cover
    width: 100%
    height: 100%
    border-top-left-radius: inherit
    border-top-right-radius: inherit
    transition: transform 200ms linear

.Player
  position: relative

.Video--Contain
  video
    object-fit: contain
    background-color: $black

.Video--Fullscreen
  video
    position: absolute
    left: 50%
    top: 0
    width: auto !important
    height: 100vh !important
    transform: translateX(-50%)
    border-radius: 0

.Video--Idle
  cursor: none

  .Controls
    transform: translateY(100%)
