@import '@/sass/colors'
@import '@/sass/mixins'

.NftAssetError
  flex-direction: column
  gap: 16px
  position: relative
  text-align: center
  z-index: 0

  &:before
    @include box-size(100%)
    background: $purple-gradient
    border-radius: 10px 10px 0 0
    content: ""
    opacity: 0.08
    position: absolute
    top: 0
    left: 0
    z-index: -1

  &_icon
    @include box-size(64px)

  &_text
    color: $black-transparent-60
    font-size: 14px
    font-weight: 500

    @include dark
      color: $white-transparent-40

  &_button
    font-size: 14px !important


