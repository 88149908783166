@import '@/sass/colors'
@import '@/sass/variables'

\:export
    headerHeight: $header-height

.Grid
    grid-template-columns: repeat(3, 1fr)
    @media screen and (max-width: 1440px) 
        grid-template-columns: repeat(3, 1fr)
    @media screen and (max-width: 1200px) 
        grid-template-columns: repeat(2, 1fr)
    @media screen and (max-width: 880px) 
        grid-template-columns: repeat(1, 1fr)