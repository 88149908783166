@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  border: 1px solid $black-transparent-10
  position: relative
  border-radius: 13px
  transition: all 200ms linear

  @include dark
    border-color: $white-transparent-10

  video, img
    transition: 300ms

  [data-play-button]
    display: none
    opacity: 0

  &:hover
    box-shadow: 0 0 30px rgba(17, 13, 33, 0.1)
    // cursor: pointer

    video, img
      transform: scale(1.1)

    [data-play-button]
      display: flex
      opacity: 100%

.Card
  background-color: $white
  border-radius: 12px
  position: relative
  overflow: hidden

  @include dark
    background-color: $dark

  &__innerShadow
    &::after
      background: linear-gradient(180deg, rgba(17, 13, 33, 0) 0%, rgba(17, 13, 33, 0.00680114) 9.42%, rgba(17, 13, 33, 0.0232296) 17.58%, rgba(17, 13, 33, 0.0485978) 24.69%, rgba(17, 13, 33, 0.082218) 30.95%, rgba(17, 13, 33, 0.123403) 36.55%, rgba(17, 13, 33, 0.171464) 41.69%, rgba(17, 13, 33, 0.225714) 46.57%, rgba(17, 13, 33, 0.285466) 51.38%, rgba(17, 13, 33, 0.350031) 56.33%, rgba(17, 13, 33, 0.418722) 61.62%, rgba(17, 13, 33, 0.490852) 67.44%, rgba(17, 13, 33, 0.565732) 73.99%, rgba(17, 13, 33, 0.642675) 81.46%, rgba(17, 13, 33, 0.720994) 90.07%, rgba(17, 13, 33, 0.8) 100%)
      bottom: 0
      content: ''
      height: 206px
      position: absolute
      width: 100%
      z-index: 1

  video
    object-fit: cover !important

.Content
  bottom: 0
  color: $dark
  padding: 16px
  position: absolute
  width: 100%
  z-index: 2

  @include dark
    color: $white

  &__loaded
    color: $white

.Title
  font-size: 16px
  line-height: 24px
  font-weight: 700
  margin-bottom: 2px

  @include text-truncate

.Code
  align-items: center
  display: flex
  font-weight: 400
  font-size: 14px
  gap: 8px
  line-height: 20px

  > p
    opacity: 0.6

.CopyIcon
  cursor: pointer
  opacity: 0.6
  transition: fill 200ms linear

  &:hover
    opacity: 1

.Amount
  align-items: center
  background: $white-transparent-60
  backdrop-filter: blur(12px)
  border-radius: 6px
  display: flex
  gap: 4px
  padding: 4px 6px 4px 4px
  position: absolute
  top: 16px
  right: 16px
  z-index: 1

  @include dark
    background: $dark-transparent-60

.Play
  background: $white-transparent-60
  backdrop-filter: blur(12px)
  border-radius: 12px
  color: $dark
  height: 80px
  left: 50%
  position: absolute
  transition: transform 250ms linear, opacity 250ms linear
  transform: translate(-50%, -50%)
  top: 50%
  width: 80px
  z-index: 2
  cursor: pointer

  &:hover
    background: $white
    transform: translate(-50%, -50%) scale(1.1)

.MenuButton
  border: none
  bottom: 16px
  color: $dark
  padding: 8px
  position: absolute
  right: 16px
  z-index: 1

  &:hover
    background: $dark-transparent-20

  &:active
    background: $dark-transparent-20

  @include dark
    color: $white

    &:hover
      background: $white-transparent-20

    &:active
      background: $white-transparent-20

  &__loaded
    color: $white

    &:hover
      background: $white-transparent-20

    &:active
      background: $white-transparent-20
