@import '@/sass/colors'
@import '@/sass/mixins'

$nft-modal-sidebar-width: 480px

.ModalContent
  background: $white-transparent-90
  backdrop-filter: blur(16px)
  border: 0
  border-radius: 0

  @include dark
    background: $dark-transparent-90

  &:before, &:after
    display: none

.ModalCloseButton
  @include box-size(32px)
  border: 1px solid rgba(0, 0, 0, 0.4)
  border-radius: 50%
  position: absolute
  left: 24px
  top: 24px
  z-index: 1

  @include dark
    border-color: $white-transparent-20
    color: $white

.ModalCollapse
  @include box-size(32px)
  border: 1px solid rgba(0, 0, 0, 0.4)
  border-radius: 50%
  display: none
  min-width: auto
  padding: 0
  position: absolute
  right: 24px
  top: 24px
  z-index: 1

  @include md
    display: inline-flex

  @include dark
    border-color: $white-transparent-20
    color: $white

  &:hover
    background: $black-transparent-6

    @include dark
      background: $white-transparent-6

.ModalBody
  padding: 0

.AssetWrapper
  height: auto
  padding: 20px
  top: 0
  width: 100%

  @include md
    float: left
    height: 100vh
    padding: 68px 20px
    position: sticky

  &__opened
    @include md
      width: calc(100vw - $nft-modal-sidebar-width)

  &_Container
    margin: auto
    max-width: calc(100vh - (68px * 2))

    > div
      border-radius: 12px

      video
        aspect-ratio: unset

.SidebarWrapper
  overflow-y: scroll
  position: relative !important
  width: 100%

  @include md
    position: fixed !important
    border-left: 1px solid $black-transparent-10
    float: right
    width: calc($nft-modal-sidebar-width - 15px) !important // 15px - scrollbar

  @include dark
    border-color: $white-transparent-10

  &__closed
    @include md
      position: fixed !important

.HeaderWrapper
  position: sticky
  top: 0
  z-index: 15

.Header
  background: $white-transparent-60
  backdrop-filter: blur(12px)
  border-bottom: 1px solid $black-transparent-10
  padding: 24px

  @include dark
    background: $dark-transparent-60
    border-color: $white-transparent-10

.StickyBarWrapper
  position: sticky
  left: 0
  bottom: 0

.StickyBar
  background: $white-transparent-60
  backdrop-filter: blur(12px)
  border-top: 1px solid $black-transparent-10
  display: flex
  flex-direction: column
  padding: 24px
  width: 100%

  @include dark
    background: $dark-transparent-60
    border-color: $white-transparent-10

  button
    font-size: 14px
