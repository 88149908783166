@import 'variables'

@mixin text-gradient($color: $purple-gradient)
  background: $color
  background-clip: text
  text-fill-color: transparent
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

@mixin text-truncate
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

@mixin box-size($size)
  height: $size
  width: $size

@mixin dark
  @at-root [data-theme=dark] &
    @content

@mixin sm($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-sm)
    @content

@mixin md($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-md)
    @content

@mixin lg($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-lg)
    @content

@mixin xl($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-xl)
    @content

@mixin xxl($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-xxl)
    @content

@mixin xl3($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-xl3)
    @content

@mixin xl4($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-xl4)
    @content

@mixin xl5($property: 'min-width')
  @media screen and (#{$property}: $breakpoint-xl5)
    @content

@mixin xl6($property: 'min-width')
  @media screen and (#{$property}-width: $breakpoint-xl6)
    @content

@mixin custom-scroll
  /* width */
  &::-webkit-scrollbar
    width: 8px

  /* Track */
  &::-webkit-scrollbar-track
    background: transparent
    border-radius: 4px

  /* Handle */
  &::-webkit-scrollbar-thumb
    background-color: $black-transparent-5
    background-clip: padding-box
    border: 1px solid transparent
    border-radius: 9999px

    @include dark
      background-color: $white-transparent-5

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover
    background-color: $black-transparent-20

    @include dark
      background-color: $white-transparent-20
