@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  border: 0 solid rgba(17, 13, 33, 0.2)
  border-radius: 8px
  padding: 60px
  text-align: center
  width: 400px

  @include dark
    border-color: rgba(255, 255, 255, 0.2)

  @include sm
    border-width: 1px

  .Description,
  .Text
    color: #A09EA6

    @include dark
      color: #706E7A

.Title
  font-weight: 700
  font-size: 26px
  margin-bottom: 10px

.Description
  font-size: 16px
  line-height: 24px

.Wallets
  margin: 30px 0

.Text
  font-size: 12px
