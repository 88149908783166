@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  background: $black-transparent-10 !important
  border: 0 !important
  border-radius: 10px !important
  cursor: pointer !important
  height: auto !important
  padding: 1px !important
  padding-inline-start: 1px !important
  padding-inline-end: 1px !important

  font-size: 14px !important
  font-weight: 500 !important

  @include dark
    background: $white-transparent-10 !important

  &:hover
    background: $purple-gradient !important

  &[aria-expanded="true"]
    background: $purple-gradient !important
    box-shadow: 0 0 0 5px rgba(192, 88, 182, 0.12) !important

  > div
    align-items: center
    background: $white
    border-radius: 9px
    display: inline-flex
    padding: 16px 12px
    width: 100%

    svg
      width: 20px

    @include dark
      background: $dark

.PopoverContent
  background: $white !important
  box-shadow: 0 2px 24px rgba(17, 13, 33, 0.08) !important
  width: auto !important

  @include dark
    background: $dark !important

.Link
  background: $black-transparent-10
  border: 0
  border-radius: 8px
  display: inline-flex
  justify-content: center
  overflow: hidden
  padding: 1px
  width: 100%

  &:not(&_disabled):hover
    background: $purple-gradient

  & > div
    background: $white
    border-radius: 8px
    display: flex
    justify-content: center
    padding: 8px
    width: 100%

  @include dark
    background: $white-transparent-10

    & > div
      background: $dark

  &_disabled
    background: transparent
    padding: 0

    @include dark
      background: transparent

    & > div
      background: $black-transparent-5
      border: 1px solid
      border-color: $black-transparent-10!important
      color: $black-transparent-20

      @include dark
        background: $white-transparent-5
        border-color: $white-transparent-10!important
        color: $white-transparent-20