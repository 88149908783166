@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  cursor: pointer
  padding: 8px 10px
  font-weight: 700
  font-size: 12px
  line-height: 16px
  background-color: $black-transparent-10
  border-radius: 8px
  text-align: center
  transition: background-color 200ms linear
  min-width: 55px
  width: fit-content
  flex-shrink: 1
  color: $dark
  backdrop-filter: blur(2px)

  @include dark
    background-color: $white-transparent-60

  &:hover
    background-color: $black-transparent-20

    @include dark
      background-color: $white-transparent-100

.isLightMode
  background-color: $white-transparent-60

  &:hover
    background-color: $white-transparent-100
