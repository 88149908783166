@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: relative
  width: 100%
  height: 100%
  background-color: $black
  border-radius: inherit
  overflow: hidden

  &.isIdle
    .Controls
      transform: translateY(100%)

    .Wrapper
      cursor: none

  &.isFullscreen
    video
      width: auto !important
      height: 100vh !important
      position: absolute
      left: 50%
      transform: translateX(-50%)
      border-radius: 0

.Controls
  position: absolute
  width: 100%
  bottom: 0
  left: 0
  padding: 12px
  z-index: 1
  transform: translateY(100%)
  transition: transform 200ms linear

  &.isLoaded
    transform: translateY(0)

.Timer
  margin-left: 12px

.Loader
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
