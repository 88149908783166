@import '@/sass/colors'
@import '@/sass/mixins'

.ColumnsNames
  display: flex
  font-size: 14px
  font-weight: 600
  justify-content: space-between
  line-height: 20px

.Item
  align-items: center
  display: flex
  gap: 12px

.ItemName, .ItemPrice
  font-size: 16px
  font-weight: 700
  line-height: 24px

.ItemCollection, .ItemUSDPrice
  color: $black-transparent-60
  font-size: 14px
  font-weight: 400
  line-height: 20px

  @include dark
    color: $white-transparent-60

.Preview
  @include box-size(64px)

  img, video
    border-radius: 12px

  [data-class='play']
    display: none
