@import '@/sass/_mixins.sass'

.ProPlanWrapper
  background: #110D21
  border: 1px solid rgba(255, 255, 255, 0.2)
  border-radius: 10px
  margin-top: 50px
  padding: 50px 15px 20px
  position: relative

  .Shadow
    @include box-size(100%)
    position: absolute
    top: 0
    left: 0
    overflow: hidden

    &:before
      @include box-size(300px)
      background: radial-gradient(50% 50% at 50% 50%, #598AFF 0%, rgba(89, 138, 255, 0) 100%)
      content: ""
      display: block
      left: 50%
      position: absolute
      opacity: 0.4
      top: -20px
      transform: translateX(-50%)

  img
    @include box-size(60px)
    position: absolute
    left: 50%
    top: 0
    transform: translate(-50%, -50%)
    z-index: 1

  p
    color: white
    font-size: 14px
    font-weight: bold
    text-align: center
    position: relative
    z-index: 1