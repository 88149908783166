@import '@/sass/_colors.sass'
@import '@/sass/_mixins.sass'

.buttonWrapper
  position: relative

  svg
    transform: scaleY(1)
    transition: transform 100ms linear

  &:before
    border-left: 1px solid $dark-transparent-10
    content: ""
    left: 0
    height: 16px
    position: absolute
    top: 50%
    transform: translateY(-50%)
    transition: opacity .2s, transform .2s

    @include dark
      border-left-color: $white-transparent-10

  &:hover
    &:before
      transform: translateX(-10px) translateY(-50%)
      opacity: 0

  &[aria-expanded=true]
    &:before
      opacity: 0

    & > button
      background: $dark-transparent-5

      @include dark
        background: $white-transparent-5

      &:before
        display: none

    svg
      transform: scaleY(-1)

.button
  align-items: center
  border-radius: 10px
  color: $dark
  height: 40px
  gap: 9px
  overflow: hidden
  padding: 8px 16px
  position: relative
  text-shadow: 1px 1px 94px rgba(0, 0, 0, 0.05)
  white-space: nowrap
  width: 140px

  &:before
    background: transparent
    top: 50%
    content: ''
    position: absolute
    left: 50%
    width: 1px
    height: 1px
    transform: translateX(-50%) translateY(-50%)
    transition: background 0.3s, transform 0.3s

  & > p
    font-weight: 600
    font-size: 15px
    line-height: 24px
    position: relative

  &:hover
    &:before
      background: $dark-transparent-5
      transform: scale(140, 40)

      @include dark
        background: $white-transparent-5

  @include dark
    color: $white

.menuContent
  background: $white
  border-radius: 8px
  flex-direction: column
  padding: 24px 8px 8px

  a
    text-decoration: none!important

  @include dark
    background: $dark

  & > h5
    color: $dark-transparent-40
    font-size: 10px
    font-weight: 500
    line-height: 14px
    letter-spacing: 0.04em
    margin-bottom: 8px
    padding: 0 16px
    text-transform: uppercase

    @include dark
      color: $white-transparent-40

  &__item
    border-radius: 8px
    cursor: pointer
    gap: 12px
    transition: background 0.2s
    padding: 13px 16px

    &__title
      font-weight: 600
      font-size: 16px
      line-height: 24px
      color: $dark

      @include dark
        color: $white

    &__description
      color: $dark-transparent-60
      font-weight: 400
      font-size: 12px
      line-height: 16px

      @include dark
        color: $white-transparent-60

    &:hover
      background: $dark-transparent-5

      .icon
        opacity: 1
        transform: translateX(0)

      @include dark
        background: $white-transparent-5

.icon
  @include box-size(24px)
  align-items: center
  align-self: center
  background: $dark-transparent-5
  border-radius: 6px
  justify-content: center
  margin-left: auto
  opacity: 0
  transition: opacity .2s, transform .2s
  transform: translateX(10px)

  @include dark
    background: $white-transparent-5

.popover
  border-color: $black-transparent-10!important

  @include dark
    border-color: $white-transparent-10!important
