@import '@/sass/colors'

.Wrapper
  position: relative
  width: 94px
  height: 94px
  border-radius: 10px

  &:before
    content: ''
    display: block
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-image: $purple-gradient
    border-radius: 12px
    z-index: -1

  &:hover
    .Handle,
    .Delete,
    .Image:before
      opacity: 1
      visibility: visible

  &.isDragging
    z-index: 1

    .Handle,
    .Delete,
    .Image:before
      opacity: 1
      visibility: visible

.Image
  position: relative
  left: 2px
  top: 2px
  width: 90px
  height: 90px
  pointer-events: none
  user-select: none

  &::before
    content: ''
    display: block
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    backdrop-filter: blur(5px)
    border-radius: 10px
    visibility: hidden
    opacity: 0
    transition: all 200ms linear

  img
    width: 90px
    height: 90px
    max-width: 100%
    object-fit: cover
    border-radius: 10px

  video
    width: 90px
    height: 90px
    object-fit: cover
    border-radius: 10px

.Handle
  cursor: grab
  position: absolute
  left: 50%
  top: 50%
  visibility: hidden
  opacity: 0
  transition: all 200ms linear
  transform: translate(-50%, -50%)

  path
    fill: $white

.Delete
  cursor: pointer
  position: absolute
  top: -10px
  right: -10px
  width: 20px
  height: 20px
  border-radius: 50%
  background-image: $purple-gradient
  visibility: hidden
  opacity: 0
  transition: all 200ms linear
  display: flex
  align-items: center
  justify-content: center

  path
    fill: $white

/****************************************************************/

.Item
  display: block
  position: relative
  outline: none
