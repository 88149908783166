@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  border: 1px solid $black-transparent-10
  border-radius: 12px
  position: relative

  @include dark
    border-color: $white-transparent-10

.Title
  position: absolute
  bottom: 16px
  left: 16px

.Amount
  position: absolute
  top: 16px
  right: 16px