@import '@/sass/colors'
@import '@/sass/mixins'

.CreateNewPlaylist
  align-items: center
  color: $black-transparent-60
  display: inline-flex
  gap: 2px
  font-size: 14px
  line-height: 20px
  margin-left: auto !important

  @include dark
    color: $white-transparent-60

  &:hover
    color: $black
    text-decoration: none

    @include dark
      color: $white

.PlaylistsAmount
  background: $black-transparent-10
  border-radius: 4px
  color: $black-transparent-60
  font-weight: 700
  font-size: 10px
  line-height: 13px
  padding: 2px 4px

  @include dark
    background: $white-transparent-10
    color: $white-transparent-60

  span
    color: $black

    @include dark
      color: $white


.PlaylistItem
  border-radius: 8px
  padding: 8px
  width: 100%

  &:not([disabled]):hover
    background: $black-transparent-5

    > span:last-child
      > span
        &:last-child
          color: $black-transparent-40

          @include dark
            color: $white-transparent-40


    @include dark
      background: $white-transparent-5

  > span:last-child
    align-items: center
    display: inline-flex
    justify-content: space-between
    font-size: 14px
    width: 100%

    > span
      display: inline-block

      &:last-child
        color: $black-transparent-20
        font-size: 12px
        text-align: right

        @include dark
          color: $white-transparent-20