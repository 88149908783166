@import '@/sass/colors'
@import '@/sass/mixins'
@import '@/sass/variables'

.Wrapper
  min-height: calc(100vh - $header-height)

  > div
    text-align: center

.StatusCode
  @include text-gradient($purple-gradient)
  line-height: 180px !important
  font-size: 200px !important
  position: relative

  margin-bottom: 40px

  &::after
    background: radial-gradient(50% 50% at 50% 50%, rgba(21, 12, 58, 0.1) 0%, rgba(21, 12, 58, 0) 100%)
    display: block
    content: ""
    position: absolute
    bottom: -15px
    height: 22px
    width: 100%

    @include dark
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(173, 173, 173, 0) 100%)