@import '@/sass/colors'

.Header
  padding: 20px 32px
  border-bottom: 1px solid

  &.Mobile
    padding: 20px

  &.Light
    background-color: $white
    border-color: $black-transparent-10

  &.Dark
    background-color: $dark
    border-color: $white-transparent-10

.Logo
  height: 40px
