.Logo
  position: relative
  width: 40px
  
  
.Icon
  height: 100%
  
.Icon--Shaddow
  filter: drop-shadow(2px 2px 10px rgba(0, 75, 255, 0.57)) drop-shadow(-2px -2px 12px rgba(255, 109, 111, 0.74))

.Logo--Gradient
  .Icon
    path
      &:nth-child(1)
        fill: url(#gradient-1)
      &:nth-child(2)
        fill: url(#gradient-2)
      &:nth-child(3)
        fill: url(#gradient-2)

.Logo--White
  .Icon
    path
      &:nth-child(n)
        fill: white

.Logo
  &.Light
    .Icon--Shaddow
      filter: none

  &.Dark
