@import '@/sass/colors'
@import '@/sass/mixins'

.SeedButton
  @include box-size(32px)

  align-items: center
  background: $black-transparent-5
  border: 0
  border-radius: 8px
  cursor: pointer
  display: flex
  justify-content: center
  min-width: auto
  padding: 0
  position: absolute
  right: 24px
  top: 24px
  transition: 200ms

  @include dark
    background: $white-transparent-5

  &:hover, &:active
    background: $black-transparent-10

    @include dark
      background: $white-transparent-10

.FeesTitle
  font-size: 12px

.Price
  font-size: 18px
  font-weight: 600
  line-height: 20px

.PriceUSD
  color: $black-transparent-60
  font-size: 12px
  font-weight: 500
  line-height: 16px

  @include dark
    color: $white-transparent-60

.ExpireInLabel
  color: $black-transparent-40
  font-size: 12px
  font-weight: 500
  line-height: 16px

  @include dark
    color: $white-transparent-40