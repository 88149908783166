@import '@/sass/colors'
@import '@/sass/mixins'
@import '@/sass/variables'

.Sidebar
  padding-top: 25px

  &.Static
    padding: 24px
    position: fixed
    left: 0
    top: 0
    height: calc(100% - $header-height)
    width: $sidebar-width
    margin-top: $header-height
    z-index: 9

.Menu
  position: relative

.Item
  cursor: pointer
  position: relative
  list-style: none
  font-weight: 500
  font-size: 14px
  line-height: 20px
  padding: 12px 15px 12px 8px
  display: block
  border-radius: 8px
  transition: all 200ms linear
  user-select: none

.isActive
  font-weight: 700

  path
    fill: url(#gradient) !important

  span
    @include text-gradient

.isDisabled
  cursor: not-allowed
  color: $black-transparent-20

  path
    fill: $black-transparent-20 !important

  &:hover
    color: $black-transparent-20 !important

  @include dark
    color: $white-transparent-20 !important

    path
      fill: $white-transparent-20 !important

    &:hover
      color: $white-transparent-20 !important

.DisabledComment
  font-size: 8px
  font-weight: 500
  line-height: 10px
  letter-spacing: 0.08em
  text-transform: uppercase

.Sidebar
  .Handle
    background: $purple-gradient
    border-radius: 0 3px 3px 0
    display: block
    height: 48px
    left: -20px
    position: absolute
    transition: transform 150ms linear
    width: 5px

  &.Static
    .Handle
      border-radius: 0 3px 3px 0
      left: -24px

.footerLinks
  cursor: pointer
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 16px
  color: $dark-flat-60

  &:hover
    color: $dark-flat-100
    text-decoration: underline

  @include dark
    color: $dark-flat-40

    &:hover
      color: $white-transparent-100

// ------------------- THEME COLORS ------------------- //

.Sidebar
  &.Light
    &.Static
      border-right: 1px solid $black-transparent-10

    .Item
      path
        fill: $dark

      &:hover
        color: $black-transparent-100
        background-color: $black-transparent-3

  &.Dark
    &.Static
      border-right: 1px solid $white-transparent-10

    .Item
      path
        fill: $white

      &:hover
        color: $white-transparent-100
        background-color: $white-transparent-5
