@import '@/sass/colors'

.Item
  position: relative
  width: 100%
  padding: 6px 15px
  border-radius: 10px
  background-clip: padding-box
  border: 1px solid
  box-sizing: border-box
  transition: all 200ms linear
  cursor: pointer
  user-select: none

  img, svg
    pointer-events: none

  &:hover
    border-color: transparent !important

    &:before
      opacity: 1

  &:before
    content: ''
    display: block
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-image: $purple-gradient
    border-radius: 10px
    z-index: -1
    margin: -1px
    border-radius: inherit
    opacity: 0
    transition: opacity 200ms linear

.Item
  &.Light
    background-color: $white
    border-color: #cfcfd3

    &:hover
      box-shadow: 0px 0px 25px rgba(61, 30, 98, 0.15)

  &.Dark
    background-color: $dark
    border-color: #413D4D

    &:hover
      box-shadow: 0px 0px 25px rgba(61, 30, 98, 0.35)
