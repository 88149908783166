@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  padding: 80px 0

.Icon
  @include box-size(78px)
  color: $red
  margin: auto
  margin-bottom: 32px

.Text
  font-size: 18px
  font-weight: 600
