@import '@/sass/colors'
@import '@/sass/mixins'
@import '@/sass/variables'

.Wrapper
  background-color: $white-transparent-40
  backdrop-filter: blur(12px)
  height: 64px

  @include dark
    background-color: $dark-transparent-60

  &_fixed
    border-top: 1px solid rgba(17, 13, 33, 0.1)
    bottom: 0
    left: 0
    padding: 0 20px
    position: fixed
    right: 0
    z-index: 15

    @include dark
      border-top: 1px solid rgba(255, 255, 255, 0.1)

    @include md
      left: $sidebar-width
      padding: 0 32px

  &_static
    display: block
    margin-top: 15px
    width: 100%
