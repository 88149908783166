@import '@/sass/_colors.sass'
@import '@/sass/_mixins.sass'

$logo-size: 64px

:export
  logoSize: $logo-size

.Wrapper
  position: relative
  display: flex
  flex-direction: column
  overflow: hidden
  border: 1px solid $black-transparent-10
  border-radius: 10px

  @include dark
    border-color: $white-transparent-10

.Banner
  height: 128px
  overflow: hidden

.Content
  position: relative
  padding: 32px 16px 16px

.Logo
  position: absolute
  border: 2px solid $white
  box-shadow: 0 0 25px rgba(61, 30, 98, 0.15)
  border-radius: 12px
  overflow: hidden
  margin: 0 auto

  transform: translateY(-100%)
  top: 16px
  left: 16px

  @include dark
    border-color: $dark

.Title
  border-radius: 8px
  display: flex
  justify-content: flex-start

.Menu
  @include box-size(24px)
  margin: auto
  overflow: hidden

.StatsWrapper
  margin-top: 16px
