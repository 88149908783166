@import '@/sass/colors'
@import '@/sass/mixins'

.Item
  cursor: pointer
  padding: 24px 0
  border-bottom: 1px solid $black-transparent-10

  &:first-child
    padding-top: 0

  &:hover
    .Button
      background-color: $black-transparent-10

      @include dark
        background-color: $white-transparent-10

  @include dark
    border-bottom: 1px solid $white-transparent-10

.Button
  display: flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  background-color: $black-transparent-5
  backdrop-filter: blur(15px)
  border-radius: 6px
  cursor: pointer
  transition: background-color 200ms linear

  @include dark
    background-color: $white-transparent-5

.Title
  font-weight: 700
  font-size: 16px
  line-height: 24px

  //@include text-truncate

.Content
  margin-top: 8px
  font-weight: 400
  font-size: 14px
  line-height: 20px
  color: $dark-flat-60
