@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  background: $white
  border: 1px solid $black-transparent-10
  border-radius: 12px
  padding: 12px

  @include dark
    background: $white-transparent-5
    border-color: transparent

.StackDivider
  border-color: $black-transparent-5

  @include dark
    border-color: $white-transparent-5

.FeeLabel
  font-size: 14px
  line-height: 20px

.FeeValue
  font-size: 14px
  font-weight: 700
  line-height: 20px
  margin-left: auto

.SubFeeLabel
  font-size: 12px
  font-weight: 400
  line-height: 16px

.SubFeeValue
  font-size: 12px
  font-weight: 700
  line-height: 16px
  margin-left: auto

.AccordionItem
  border: 0

.AccordionButton
  gap: 4px
  padding: 0

  &:hover
    background: transparent

  &_icon
    @include box-size(20px)
    transition: 200ms

.AccordionPanel
  background: $black-transparent-5
  border-radius: 8px
  padding: 8px 10px
  margin-top: 8px

  @include dark
    background: $white-transparent-5