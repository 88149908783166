@import '@/sass/colors'
@import '@/sass/mixins'

$primary-gradient: linear-gradient(145.9deg, #DD459F 5.46%, #FF2D83 30.27%, #6695FF 88.28%)

.Wrapper
  background: $white
  backdrop-filter: blur(10px)
  border: 1px solid $red
  border-radius: 10px
  bottom: 140px
  box-shadow: 0 0 25px rgba(61, 30, 98, 0.15)
  display: flex
  left: calc(150px + 50%)
  margin: auto
  padding: 10px 20px
  position: fixed
  transform: translateX(-50%)
  z-index: 3

  @include dark
    background: $dark-transparent-60

.Link
  @include text-gradient($primary-gradient)
  margin-left: 100px
  position: relative
  text-decoration: none

  &:before
    background: $primary-gradient
    border-radius: 2px
    content: ""
    position: absolute
    bottom: 2px
    width: 100%
    left: 0
    height: 1px
    transition: 200ms

  &:hover
    &:before
      opacity: 0

.ModalOverlay
  background: $white-transparent-40 !important
  backdrop-filter: blur(20px)

  @include dark
    background: $dark-transparent-40 !important

.ModalContent
  background: $white
  border: 1px solid $dark-transparent-20 !important
  border-radius: 10px !important
  box-shadow: 0 0 25px rgba(61, 30, 98, 0.15) !important
  position: relative
  max-width: 850px !important
  overflow: hidden
  z-index: 0

  @include dark
    background: $dark
    border-color: $white-transparent-20 !important

.ModalBody
  padding: 80px !important

.ModalCloseButton
  @include box-size(34px !important)
  background: $white
  border: 1px solid $dark-transparent-20
  border-radius: 50% !important
  top: 16px !important
  right: 16px !important
  z-index: 1

  @include dark
    background: $dark
    border: 1px solid $white-transparent-20