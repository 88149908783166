@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: relative
  width: 100%
  border-radius: 12px
  transition: all 200ms linear

  &:before
    content: ''
    display: block
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-image: $purple-gradient
    border-radius: 13px
    z-index: -1
    margin: -2px
    opacity: 0
    transition: opacity 200ms linear

  &.isSelecteble
    cursor: pointer
    user-select: none

  &.isSelected

    &:before
      opacity: 1

  &:hover
    box-shadow: 0px 0px 30px #0C091A

    .Asset
      img
        transform: scale(1.05)

      [data-class='badges']
        opacity: 1
        visibility: visible

      [data-class='controls']
        opacity: 1
        visibility: visible

    @include dark
      .Content
        background-color: #1b172a

.isShowActionButton
  &:hover
    .Listing__Wrapper
      margin-right: 83px

    .Listing__Action
      visibility: visible
      opacity: 1

    .Listing__Time
      display: none

.Asset
  border-bottom-left-radius: 0 !important
  border-bottom-right-radius: 0 !important

  img
    transform: scale(1)
    transition: transform 200ms linear
    object-fit: cover

.Content
  border-bottom-left-radius: inherit
  border-bottom-right-radius: inherit
  transition: background-color 200ms linear

  @include dark
    background-color: $dark

.TopContent
  padding: 14px

.TitleWrapper
  @include text-truncate

.Title
  //
  max-width: 100%
  font-weight: 600
  font-size: 14px
  line-height: 20px
  white-space: pre-wrap

  @include text-truncate

.ActionButton
  //

.Collection
  font-weight: 600
  font-size: 12px
  line-height: 18px

  @include text-truncate

.Footer
  //margin-top: 14px
  //padding-top: 14px

.Listing
  position: relative
  padding-left: 8px
  padding-bottom: 8px
  padding-right: 8px

.Listing__Wrapper
  width: 100%
  padding: 10px 14px
  background-color: $black-transparent-5
  border-radius: 10px

  @include dark
    background-color: $white-transparent-5

.Listing__Status
  font-weight: 600
  font-size: 14px
  line-height: 20px

.Listing__Time
  font-weight: 500
  font-size: 12px
  line-height: 16px
  color: $dark-flat-40

  @include dark
    color: $white-flat-40

.Listing__Action
  width: 75px
  position: absolute
  right: 8px
  visibility: hidden
  opacity: 0

.MagnetIcon
  background-color: $black-transparent-5
  border-radius: 8px
  color: $black-transparent-40
  padding: 6px

  @include dark
    background-color: $white-transparent-5
    color: $white-transparent-40

.Button
  path
    transition: fill 200ms linear

.WrapperSkeleton
  border-radius: 12px

  &.Light
    box-shadow: 0px 0px 0px 1px $black-transparent-10

  &.Dark
    box-shadow: 0px 0px 0px 1px $white-transparent-10

.Wrapper
  &.Light
    background-color: $white
    box-shadow: 0px 0px 0px 1px $black-transparent-10

    &:hover
      box-shadow: 0px 0px 30px rgba(17, 13, 33, 0.2)

    .Collection
      color: $black-transparent-40

    .Footer
      border-color: $black-transparent-10

    .Token
      color: $black-transparent-40
      background-color: $black-transparent-5

    .Button
      path
        fill: $black-transparent-40

      &:hover
        path
          fill: $black-transparent-60

  &.Dark
    background-color: $dark
    box-shadow: 0px 0px 0px 1px $white-transparent-10

    &:hover
      border-color: $white-transparent-20

    .Collection
      color: $white-transparent-40

    .Footer
      border-color: $white-transparent-10

    .Token
      color: $white-transparent-40
      background-color: $white-transparent-5

    .Button
      path
        fill: $white-transparent-40

      &:hover
        path
          fill: $white-transparent-60

.SelectedLabel
  position: absolute
  top: 12px
  left: 12px
  padding: 0 5px
  min-width: 24px
  height: 24px
  background: $white-transparent-40
  border-radius: 6px
  font-size: 12px
  line-height: 24px
  color: white
  text-align: center
  z-index: 2
  box-shadow: 0 0 0 2px $dark-transparent-10
  transition: background 200ms linear

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    border-radius: 6px
    background: linear-gradient(121.93deg, #FF7F69 4.09%, #FF2D83 28.55%, #6695FF 85.73%)
    opacity: 0
    visibility: hidden
    transition: opacity 200ms linear
    z-index: -1

  &.isSelected
    background: transparent

    &:before
      opacity: 100%
      visibility: visible

.PlaylistItem
  border-radius: 6px
  padding: 6px
  width: 100%

  span
    font-size: 12px !important
    line-height: 12px !important

  &:hover
    background: $black-transparent-5

    @include dark
      background: $white-transparent-10

.Size--sm
  .Listing
    padding-left: 6px
    padding-bottom: 6px
    padding-right: 6px

  .Listing__Wrapper
    padding: 8px
    border-radius: 8px

  .Listing__Status
    font-size: 12px
    line-height: 16px

  .Listing__Action
    width: 50px
    right: 6px

  &.isShowActionButton
    &:hover
      .Listing__Wrapper
        margin-right: 55px
