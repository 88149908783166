@import '@/sass/colors'
@import '@/sass/mixins'

.Image
  display: flex
  justify-content: center

.Text
  text-align: center
  font-weight: 700
  font-size: 20px
  line-height: 32px
  margin-top: 24px
