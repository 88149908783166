@import '@/sass/colors'
@import '@/sass/mixins'

.Label
  font-size: 12px
  line-height: 16px

.PopoverContent
  width: 100%

.PopoverBody
  display: flex
  flex-direction: column
  gap: 24px
  padding: 24px

.TriggerButton
  justify-content: flex-start
  gap: 8px
  padding: 0 16px
  width: 100%

.InfoTooltipWrapper
  color: $black-transparent-40
  cursor: pointer

  &:hover
    color: $black

    @include dark
      color: $white

  @include dark
    color: $white-transparent-40

.FormControl
  display: flex
  flex-direction: column
  gap: 6px

.ChangeMonthButton
  @include box-size(32px)
  border: 0
  padding: 0
  min-width: auto

  &:hover
    background-color: $black-transparent-5

    @include dark
      background-color: $white-transparent-5

.DateInput
  font-weight: 500

.DatePicker
  :global(.react-datepicker)
    border: 0 !important
    width: 100%

  :global(.react-datepicker)
    background: transparent
    color: $black

    @include dark
      color: $white

  :global(.react-datepicker__header)
    background: transparent
    border: 0

  :global(.react-datepicker__month-container)
    width: 100%

  :global(.react-datepicker__month)
    margin: 0

  :global(.react-datepicker__day-name),
  :global(.react-datepicker__day)
    font-family: 'Space Grotesk'
    font-weight: 500
    font-size: 14px
    line-height: 20px
    padding: 6px

  :global(.react-datepicker__day)
    color: $black

    &:not(:global(.react-datepicker__day--disabled)):hover
      background-color: $black-transparent-5

      @include dark
        background-color: $white-transparent-5

    @include dark
      color: $white

  :global(.react-datepicker__day-name)
    color: $black-transparent-40

    @include dark
      color: $white-transparent-40

  :global(.react-datepicker__day--keyboard-selected)
    background: transparent
    color: $black

    @include dark
      color: $white

  :global(.react-datepicker__day--in-range),
  :global(.react-datepicker__day--in-selecting-range)
    background-color: $black-transparent-5
    border-radius: 0
    color: $black

    @include dark
      background-color: $white-transparent-5
      color: $white

  :global(.react-datepicker__day--outside-month)
    visibility: hidden

  :global(.react-datepicker__day--disabled)
    color: $black-transparent-20

    @include dark
      color: $white-transparent-20

  :global(.react-datepicker__week > .react-datepicker__day--in-selecting-range:nth-of-type(1)),
  :global(.react-datepicker__week > .react-datepicker__day--in-range:nth-of-type(1)),
  :global(.react-datepicker__week > .react-datepicker__day--range-start),
  :global(.react-datepicker__day--selected)
    border-top-left-radius: 8px
    border-bottom-left-radius: 8px

  :global(.react-datepicker__week > .react-datepicker__day--in-selecting-range:nth-of-type(7)),
  :global(.react-datepicker__week > .react-datepicker__day--in-range:nth-of-type(7)),
  :global(.react-datepicker__week > .react-datepicker__day--range-end)
    border-top-right-radius: 8px
    border-bottom-right-radius: 8px

  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--range-start),
  :global(.react-datepicker__day--range-end)
    background-color: $black-transparent-5
    color: $white
    position: relative
    z-index: 0

    @include dark
      background-color: $white-transparent-5

    &::before
      background: linear-gradient(121.93deg, #FF7F69 4.09%, #FF2D83 28.55%, #6695FF 85.73%)
      border-radius: 8px
      color: $white
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      content: ''
      z-index: -1

  :global(.react-datepicker__day-name),
  :global(.react-datepicker__day),
  :global(.react-datepicker__time-name)
    margin: 1px 0
    width: calc(100% / 7)