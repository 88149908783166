@import '@/sass/colors'
@import '@/sass/mixins'

$container-width: 100%

.Container
  position: relative
  border: 2px dashed
  transition: all 150ms linear
  cursor: pointer

  &::before
    content: ''
    position: absolute
    left: -1px
    top: -1px
    width: calc(100% + 2px)
    height: calc(100% + 2px)
    border-radius: inherit
    background: $purple-gradient
    opacity: 0
    transition: all 150ms linear

  &.Uploaded
    cursor: unset

    .IconUpload,
    .UploadArea,
    &::before
      display: none

    &:hover
      .EditIcon
        visibility: visible
        opacity: 1

  &.Loading
    cursor: progress
    pointer-events: none

  &:hover,
  &.Dragging

    &::before
      opacity: 20%

    .Overlay
      opacity: 10%

    .Icon
      opacity: 100%

.Loader
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.UploadArea
  display: block
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 1

.Icon
  display: block
  width: 35px
  height: 35px
  opacity: 20%
  transition: all 200ms linear

  path
    transition: all 200ms linear

.Content
  position: absolute
  left: 50%
  top: 50%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  transform: translate(-50%, -50%)

.Text
  font-size: 14px
  font-weight: 500
  margin-top: 10px
  transition: all 200ms linear

.ImageContainer
  position: relative
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.Image
  display: block
  object-fit: cover

.Video
  width: 100%
  height: 100%
  border-radius: 10px

  video
    border-radius: 10px

.Overlay
  position: absolute
  left: -1px
  top: -1px
  width: calc(100% + 2px)
  height: calc(100% + 2px)
  background: $purple-gradient
  cursor: pointer
  opacity: 0
  transition: all 150ms linear

.EditIcon
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  visible: hidden
  opacity: 0
  transition: all 200ms linear
  pointer-events: none

  svg
    width: 50px
    height: 50px

  path
    fill: $white

.Remove
  position: absolute
  z-index: 2

  &:hover
    border-color: $red

    path
      fill: $red

// ------------------------------------------ //

.Container
  &.Area
    width: 100%
    height: fit-content
    min-height: 150px
    border-radius: 10px

    .ImageContainer
      padding: 40px
      min-height: 150px

    .Image
      max-height: 300px
      margin: 0 auto
      border-radius: 10px

    .Overlay
      border-radius: 10px

    .UploadArea
      border-radius: 10px

    .Remove
      right: 10px
      top: 10px

  &.Uploaded
    border-style: solid

  &.Round
    width: 120px
    height: 120px
    border-radius: 12px

    .Image
      width: 100%
      height: 100%
      border-radius: 12px

    .Overlay
      border-radius: 12px

    .UploadArea
      border-radius: 12px

    .Remove
      right: 0
      top: 0

// ------------------------------------------ //

.Light
  &.Container
    background-color: $black-transparent-2
    border-color: $black-transparent-5

  &:hover,
  &.Dragging
    border-color: $purple-light

    .Text
      color: $black-transparent-100

    .IconUpload
      path
        fill: $black

  .Text
    color: $black-transparent-30

.Dark
  &.Container
    background-color: $black-transparent-10
    border-color: $white-transparent-5

  &:hover,
  &.Dragging
    border-color: $purple-light

    .Text
      color: $white-transparent-100

  .IconUpload
    path
      fill: $white

  .Overlay
    background-color: rgb(0 0 0 / 30%)

  .Text
    color: $white-transparent-20

.Error
  color: $dark-flat-60

  @include dark
    color: $white-flat-60
