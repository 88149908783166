@import '@/sass/colors'
@import '@/sass/mixins'

.Button
  display: flex
  justify-content: center
  align-items: center
  width: 32px
  height: 32px
  min-width: 32px
  border-radius: 8px
  background-color: $black-transparent-10
  cursor: pointer
  transition: background-color 200ms linear
  flex-shrink: 0
  backdrop-filter: blur(2px)

  @include dark
    background-color: $white-transparent-60

  &:hover
    background-color: $black-transparent-20

    @include dark
      background-color: $white-transparent-100

.Icon
  color: $dark

.isLightMode
  background-color: $white-transparent-60

  &:hover
    background-color: $white-transparent-100
