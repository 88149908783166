.Wrapper
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  border-radius: inherit

  &::before
    content: ''
    display: block
    position: absolute
    left: 0
    top: 0
    background: linear-gradient(121.93deg, #FF7F69 4.09%, #FF2D83 28.55%, #6695FF 85.73%)
    opacity: 10%
    border-radius: inherit
    width: 100%
    height: 100%
