@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  background-color: $white-transparent-100
  border: 1px solid $black-transparent-20
  border-radius: 12px
  font-weight: 500
  font-size: 16px
  line-height: 24px

  @include dark
    background-color: transparent
    border-color: $white-transparent-20

  .isDisabled
    .Item
      color: $dark-flat-20
      cursor: not-allowed

.List
  width: 100%
  height: 100%
  padding: 4px
  justify-content: space-between

.Item
  width: 100%
  font-size: 14px
  color: $black-transparent-40
  height: 100%
  border-radius: 8px
  user-select: none
  cursor: pointer
  transition: color 200ms linear
  text-align: center

  @include dark
    color: $white-transparent-40

  &:hover
    background: $black-transparent-5

    @include dark
      background: $white-transparent-5

  &.Item--Active
    background: $black-transparent-10
    color: $black-transparent-100

    @include dark
      background: $white-transparent-10
      color: $white-transparent-100

  &.Item--Disabled
    pointer-events: none
    background: none
    color: $black-transparent-20
    cursor: not-allowed

    @include dark
      color: $white-transparent-20

  &.Item--WithoutLabel
    .Icon
      margin-right: 0 !important

.Icon
  display: inline-block

.WithoutTitle
  margin-right: 0

.Size--sm
  height: 32px

  .Item
    padding: 4px 8px

    .Icon
      width: 16px
      height: 16px
      margin-right: 8px

      svg
        width: 16px
        height: 16px

  .Item--WithoutLabel
    padding: 4px

.Size--md
  height: 40px

  .Item
    padding: 6px 12px

    .Icon
      width: 20px
      height: 20px
      margin-right: 12px

      svg
        width: 20px
        height: 20px

  .Item--WithoutLabel
    padding: 6px

.Size--lg
  height: 48px

  .Item
    padding: 8px 16px

    .Icon
      width: 24px
      height: 24px
      margin-right: 12px

      svg
        width: 24px
        height: 24px

  .Item--WithoutLabel
    padding: 8px
