@import '@/sass/colors'
@import '@/sass/mixins'
@import '@/sass/variables'

\:export
  headerHeight: $header-height

.Wrapper
  padding-top: 32px
  padding-bottom: 75px

.ErrorWrapper
  // 80px - padding
  // 86px - pagination
  // 47px - tabs
  height: calc(100vh - $header-height - 80px - 86px - 47px)

.ErrorIcon
  @include box-size(78px)
  color: $red
  margin: auto
  margin-bottom: 32px