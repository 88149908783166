@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: absolute
  top: 15px
  right: 15px
  display: block
  visibility: hidden
  opacity: 0
  transition: opacity 200ms linear
  z-index: 1

  &.isPlaying
    opacity: 1
    visibility: visible

.Item
  width: 20px
  height: 20px
  display: flex
  align-items: center
  justify-content: center
  background: $white-transparent-40
  border-radius: 4px

  [role="button"]
    cursor: unset

.Icon
  path
    fill: $black
