
$white: #fff
$white-transparent-100: rgba($white, 100)
$white-transparent-90: rgba($white, 0.90)
$white-transparent-80: rgba($white, 0.80)
$white-transparent-70: rgba($white, 0.70)
$white-transparent-60: rgba($white, 0.60)
$white-transparent-50: rgba($white, 0.50)
$white-transparent-40: rgba($white, 0.40)
$white-transparent-30: rgba($white, 0.30)
$white-transparent-20: rgba($white, 0.20)
$white-transparent-10: rgba($white, 0.10)
$white-transparent-9: rgba($white, 0.09)
$white-transparent-8: rgba($white, 0.08)
$white-transparent-7: rgba($white, 0.07)
$white-transparent-6: rgba($white, 0.06)
$white-transparent-5: rgba($white, 0.05)
$white-transparent-4: rgba($white, 0.04)
$white-transparent-3: rgba($white, 0.03)
$white-transparent-2: rgba($white, 0.02)
$white-transparent-1: rgba($white, 0.01)

$black: #000
$black-transparent-100: rgba($black, 100)
$black-transparent-90: rgba($black, 0.90)
$black-transparent-80: rgba($black, 0.80)
$black-transparent-70: rgba($black, 0.70)
$black-transparent-60: rgba($black, 0.60)
$black-transparent-50: rgba($black, 0.50)
$black-transparent-40: rgba($black, 0.40)
$black-transparent-30: rgba($black, 0.30)
$black-transparent-20: rgba($black, 0.20)
$black-transparent-10: rgba($black, 0.10)
$black-transparent-9: rgba($black, 0.09)
$black-transparent-8: rgba($black, 0.08)
$black-transparent-7: rgba($black, 0.07)
$black-transparent-6: rgba($black, 0.06)
$black-transparent-5: rgba($black, 0.05)
$black-transparent-4: rgba($black, 0.04)
$black-transparent-3: rgba($black, 0.03)
$black-transparent-2: rgba($black, 0.02)
$black-transparent-1: rgba($black, 0.01)

$dark: #110d21
$dark-transparent-100: rgba($dark, 100)
$dark-transparent-90: rgba($dark, 0.90)
$dark-transparent-80: rgba($dark, 0.80)
$dark-transparent-70: rgba($dark, 0.70)
$dark-transparent-60: rgba($dark, 0.60)
$dark-transparent-50: rgba($dark, 0.50)
$dark-transparent-40: rgba($dark, 0.40)
$dark-transparent-30: rgba($dark, 0.30)
$dark-transparent-20: rgba($dark, 0.20)
$dark-transparent-10: rgba($dark, 0.10)
$dark-transparent-5: rgba($dark, 0.05)
$dark-transparent-4: rgba($dark, 0.04)
$dark-transparent-3: rgba($dark, 0.03)
$dark-transparent-2: rgba($dark, 0.02)
$dark-transparent-1: rgba($dark, 0.01)

$purple-light: #b5a5f6
$purple-light-transparent-100: rgba($purple-light, 100)
$purple-light-transparent-90: rgba($purple-light, 0.90)
$purple-light-transparent-80: rgba($purple-light, 0.80)
$purple-light-transparent-70: rgba($purple-light, 0.70)
$purple-light-transparent-60: rgba($purple-light, 0.60)
$purple-light-transparent-50: rgba($purple-light, 0.50)
$purple-light-transparent-40: rgba($purple-light, 0.40)
$purple-light-transparent-30: rgba($purple-light, 0.30)
$purple-light-transparent-20: rgba($purple-light, 0.20)
$purple-light-transparent-10: rgba($purple-light, 0.10)
$purple-light-transparent-5: rgba($purple-light, 0.05)
$purple-light-transparent-4: rgba($purple-light, 0.04)
$purple-light-transparent-3: rgba($purple-light, 0.03)
$purple-light-transparent-2: rgba($purple-light, 0.02)
$purple-light-transparent-1: rgba($purple-light, 0.01)

$green: #0eb051
$green-transparent-10: rgba($green, 0.10)

$red: #ff4949
$red-transparent-10: rgba($red, 0.10)

$purple-gradient: linear-gradient(120deg, #ff7f69 5%, #ff2d83 30%, #6695ff 85%)

$dark-flat-100: #110d21
$dark-flat-60: #706e7a
$dark-flat-40: #a09ea6
$dark-flat-20: #cfcfd3
$dark-flat-10: #e8e7e9
$dark-flat-5: #f3f3f4

$white-flat-100: #fff
$white-flat-60: #9e9da3
$white-flat-40: #6d6b75
$white-flat-20: #3d3a47
$white-flat-10: #242130
$white-flat-5: #181625

$blue: #4D66EB
$blue-transparent-100: rgba($blue, 100)
$blue-transparent-90: rgba($blue, 0.90)
$blue-transparent-80: rgba($blue, 0.80)
$blue-transparent-70: rgba($blue, 0.70)
$blue-transparent-60: rgba($blue, 0.60)
$blue-transparent-50: rgba($blue, 0.50)
$blue-transparent-40: rgba($blue, 0.40)
$blue-transparent-30: rgba($blue, 0.30)
$blue-transparent-20: rgba($blue, 0.20)
$blue-transparent-10: rgba($blue, 0.10)
$blue-transparent-9: rgba($blue, 0.09)
$blue-transparent-8: rgba($blue, 0.08)
$blue-transparent-7: rgba($blue, 0.07)
$blue-transparent-6: rgba($blue, 0.06)
$blue-transparent-5: rgba($blue, 0.05)
$blue-transparent-4: rgba($blue, 0.04)
$blue-transparent-3: rgba($blue, 0.03)
$blue-transparent-2: rgba($blue, 0.02)
$blue-transparent-1: rgba($blue, 0.01)
