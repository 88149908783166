@import '@/sass/colors'
@import '@/sass/mixins'

.PopoverContent
  max-width: 200px
  border-color: $black-transparent-10 !important

  @include dark
    border-color: $white-transparent-10 !important

.StackDivider
  border-color: $black-transparent-5

  @include dark
    border-color: $white-transparent-5

.Label
  color: $black-transparent-60
  font-size: 12px
  line-height: 16px
  margin-bottom: 4px

  @include dark
    color: $white-transparent-60

.Value
  color: $black
  display: block
  font-size: 14px
  font-weight: 600
  line-height: 20px
  white-space: nowrap

  @include dark
    color: $white
