@font-face
  font-family: 'Space Grotesk'
  font-weight: 300
  font-style: normal
  font-display: swap
  src: url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.eot')
  src: url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.woff2') format('woff2'), url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.woff') format('woff'), url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.ttf') format('truetype'), url('/assets/fonts/SpaceGrotesk/Light/SpaceGrotesk-Light.svg#SpaceGrotesk-Light') format('svg')

@font-face
  font-family: 'Space Grotesk'
  font-weight: 400
  font-style: normal
  font-display: swap
  src: url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.eot')
  src: url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.woff2') format('woff2'), url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.woff') format('woff'), url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.ttf') format('truetype'), url('/assets/fonts/SpaceGrotesk/Regular/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular') format('svg')

@font-face
  font-family: 'Space Grotesk'
  font-weight: 500
  font-style: normal
  font-display: swap
  src: url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.eot')
  src: url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.woff2') format('woff2'), url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.woff') format('woff'), url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.ttf') format('truetype'), url('/assets/fonts/SpaceGrotesk/Medium/SpaceGrotesk-Medium.svg#SpaceGrotesk-Medium') format('svg')

@font-face
  font-family: 'Space Grotesk'
  font-weight: 600
  font-style: normal
  font-display: swap
  src: url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.eot')
  src: url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.woff2') format('woff2'), url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.woff') format('woff'), url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.ttf') format('truetype'), url('/assets/fonts/SpaceGrotesk/SemiBold/SpaceGrotesk-SemiBold.svg#SpaceGrotesk-SemiBold') format('svg')

@font-face
  font-family: 'Space Grotesk'
  font-weight: 700
  font-style: normal
  font-display: swap
  src: url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.eot')
  src: url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.woff2') format('woff2'), url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.woff') format('woff'), url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.ttf') format('truetype'), url('/assets/fonts/SpaceGrotesk/Bold/SpaceGrotesk-Bold.svg#SpaceGrotesk-Bold') format('svg')
