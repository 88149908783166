@import '@/sass/colors'
@import '@/sass/mixins'
@import '@/sass/variables'

\:export
  sidebarWidth: $sidebar-width

.Wrapper
  background-color: #fff
  border-top: 1px solid
  border-color: $black-transparent-10
  padding: 20px

  @include dark
    border-color: $white-transparent-10
    background-color: $dark

  @include md
    padding: 20px 32px 24px

  [data-label]
    font-size: 12px

.DesktopWrapper
  align-items: center
  display: flex
  flex-direction: column
  width: 100%

  @include lg
    align-items: end
    flex-direction: row

.MobileWrapper
  bottom: 0
  left: 0
  position: fixed

.FieldWrapper
  flex: 1
  max-width: 100%

  @include xxl
    max-width: 180px

  &_delay
    @include xxl
      max-width: 86px

.Button
  font-size: 14px
  padding: 0 20px
  width: 100%

  @include lg
    width: fit-content

  &_reset
    font-weight: bold !important

.TapToEditWrapper
  display: block
  position: absolute
  left: 50%
  text-align: center
  top: 0
  transform: translate(-50%, -28px)
  width: 50%

  @include lg
    display: none

  &_line
    background: $dark
    border-radius: 100px
    height: 3px
    margin: auto
    max-width: 160px
    width: 100%

    @include dark
      background: $white
