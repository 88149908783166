@import '@/sass/colors'
@import '@/sass/mixins'

.Gem
  @include box-size(32px)
  align-items: center
  background: $black-transparent-5
  border-radius: 8px
  display: flex
  justify-content: center
  position: absolute
  right: 24px
  top: 24px

  @include dark
    background: $white-transparent-5

  &:hover
    background: $black-transparent-10

    @include dark
      background: $white-transparent-10

.AmountInPlaylist
  color: $dark-transparent-60
  font-size: 12px
  font-weight: 600

  @include dark
    color: $white-transparent-60

  strong
    color: $dark

    @include dark
      color: $white
