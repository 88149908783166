@import '@/sass/colors'
@import '@/sass/mixins'

.Title
  font-size: 16px
  font-weight: 600
  line-height: 24px

.Description
  color: $black-transparent-60
  font-size: 14px
  font-weight: 400
  line-height: 20px

  @include dark
    color: $white-transparent-60

  span
    color: $black

    @include dark
      color: $white

.TxTitle
  font-size: 14px
  font-weight: 700
  line-height: 20px

.TxLink
  color: $blue
  font-weight: 400
  font-size: 14px
  line-height: 20px
