@import '@/sass/colors'
@import '@/sass/mixins'

.NFTName
  font-size: 24px

.CollectionDetails
  margin-bottom: 8px

  img
    @include box-size(32px)
    object-fit: cover
    border: 1px solid $black-transparent-10
    border-radius: 8px

    @include dark
      border-color: $white-transparent-10

  h2
    font-size: 16px

.OwnedBy
  color: $black-transparent-60
  font-size: 12px

  @include dark
    color: $white-transparent-60

  a
    align-items: center
    color: $black
    display: inline-flex
    font-weight: bold

    @include dark
      color: $white

.DescriptionTitle
  font-size: 16px
  margin-bottom: 12px

.DescriptionText
  font-size: 14px