@import '@/sass/colors'
@import '@/sass/mixins'

.Property
  background: $white
  border: 1px solid $black-transparent-10
  border-radius: 12px
  padding: 10px 12px
  display: flex
  flex-direction: column
  gap: 4px

  @include dark
    background: $white-transparent-5
    border-color: transparent

  > p
    &:nth-child(1)
      color: $black-transparent-60
      font-size: 12px
      line-height: 16px

      @include dark
        color: $white-transparent-60

    &:nth-child(2)
      color: $black
      font-size: 14px
      font-weight: bold
      line-height: 16px

      @include dark
        color: $white

    &:nth-child(3)
      color: $black-transparent-60
      font-size: 12px
      line-height: 18px

      @include dark
        color: $white-transparent-60