@import '@/sass/colors'

.Wrapper
  width: 28px
  height: 28px
  border: 1px solid
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  transition: all 200ms linear

  path
    transition: all 200ms linear

.Light
  background: $white
  border-color: $dark-transparent-20

  &:hover
    border-color: $dark-transparent-40

  .Icon
    path
      fill: $dark

.Dark
  background: $dark
  border-color: $white-transparent-20

  &:hover
    border-color: $white-transparent-40

  .Icon
    path
      fill: $white
