.Wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  padding: 50px 0

.Title
  font-size: 30px
  font-weight: 700

.Description
  font-size: 16px
  font-weight: 300
  line-height: 24px
  margin-top: 15px
