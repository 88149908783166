@import '@/sass/variables'

.Wrapper
  position: relative

.Header
  position: fixed
  top: 0
  width: 100%
  z-index: 15
  height: $header-height

  &.Mobile
    height: $header-height

.Page
  position: relative
  min-height: 100%
  width: 100%
  padding-top: $header-height

  &.Mobile
    padding-top: $header-height

.Content
  position: relative
  height: 100%
  min-height: 100%
  width: calc(100% - $sidebar-width)
  margin-left: $sidebar-width
  //overflow: hidden

  &.Mobile
    width: 100%
    margin-left: 0
    padding-left: 20px
    padding-right: 20px
    padding-top: 20px
    padding-bottom: 20px
