@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  //

.List
  padding: 20px 0
  min-height: 134px

.Headline
  font-weight: 700
  line-height: 18px

.Counter
  padding: 2px 6px
  background: $black-transparent-10
  color: $black-transparent-40
  border-radius: 4px
  font-weight: 700
  font-size: 12px

.Placeholder
  @include box-size(90px)
  border: 1px dashed rgba(17, 13, 33, 0.2)
  border-radius: 10px

  @include dark
    border-color: rgba(255, 255, 255, 0.2)
