@import '@/sass/colors'
@import '@/sass/mixins'

.Close
  position: absolute
  top: 16px
  right: 16px

.ModalContent
  max-width: 520px

.ModalBody
  padding: 56px
  text-align: center

.Title
  font-size: 24px
  line-height: 32px

.Text
  color: $black-transparent-60
  font-size: 14px

  @include dark
    color: $white-transparent-60

  span
    color: $black

    @include dark
      color: $white


.SwitchNetworkButton
  margin-left: auto !important
  margin-right: auto !important
  width: fit-content