@import '@/sass/colors'
@import '@/sass/mixins'

$cardImageBg: linear-gradient(121.93deg, rgba(255, 127, 105, 0.1) 4.09%, rgba(255, 45, 131, 0.1) 28.55%, rgba(102, 149, 255, 0.1) 85.73%)

.Image
  background: $cardImageBg white
  border: 2px solid $white-transparent-100
  border-radius: 12px
  bottom: calc(100% - 16px)
  left: 16px
  object-fit: cover
  position: absolute
  z-index: 1

  @include box-size(64px)

  @include dark
    background: $cardImageBg $dark
    border: 2px solid $dark

.CardBanner
  position: relative
  height: 128px
  border-top-left-radius: inherit
  border-top-right-radius: inherit

  img
    border-top-left-radius: inherit
    border-top-right-radius: inherit

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    background: $purple-gradient
    border-top-left-radius: inherit
    border-top-right-radius: inherit
    opacity: 0.1

.Card
  background: $white-transparent-100
  border: 1px solid $black-transparent-10
  border-radius: 12px
  cursor: pointer
  height: 100%
  position: relative
  transition: 150ms
  text-align: center

  &:hover
    box-shadow: 0 0 30px rgba(17, 13, 33, 0.1)

  @include dark
    background: $dark
    border: 1px solid $white-transparent-10

    &:hover
      box-shadow: 0 0 30px rgba(181, 165, 246, 0.1)

.Content
  position: relative
  padding: 16px
  padding-top: 32px

.Counter
  font-size: 14px
  font-weight: 500
  align-items: center
  background: $black-transparent-40
  border: 1px solid rgba(255, 255, 255, 0.1)
  border-radius: 8px
  color: white
  display: flex
  gap: 4px
  right: 12px
  padding: 4px 8px
  position: absolute
  top: 12px
  //z-index: 1

.Title
  font-weight: 700
  font-size: 16px
  line-height: 24px

  @include text-truncate

.MenuButton
  color: $black-transparent-40

  @include dark
    color: $white-transparent-40

.MenuList
  min-width: 100px
  padding: 8px
  border-radius: 10px
  box-shadow: 0px 0px 25px rgba(61, 30, 98, 0.15)
  border: 1px solid $black-transparent-10 !important
  background-color: $white !important
  color: $black !important

  @include dark
    border-color: $white-transparent-10 !important
    background-color: $dark !important
    color: $white !important

.MenuItem
  padding: 10px 12px
  font-weight: 400
  font-size: 14px
  line-height: 20px
  transition: background-color 200ms linear

  &:hover
    background-color: $black-transparent-5

    @include dark
      background-color: $white-transparent-5

.StatsWrapper
  margin-top: 16px

.StatsItem
  text-align: left

.StatsItem__Value
  font-weight: 600
  font-size: 14px
  line-height: 20px
  margin-bottom: 2px

.StatsItem__Title
  font-weight: 400
  font-size: 12px
  line-height: 16px
  color: $dark-flat-60

  @include dark
    color: $white-flat-40
