@import '@/sass/colors'
@import '@/sass/mixins'

.InfoTooltip
  cursor: pointer

  path
    fill: $black-transparent-40
    transition: all 200ms linear

    @include dark
      fill: $white-transparent-40

  &:hover
    path
      fill: $black-transparent-100

      @include dark
        fill: $white-transparent-100

.PoweredByWrapper
  align-items: center
  background: rgba(255, 255, 255, 0.06)
  border-radius: 6px
  display: flex
  gap: 2px
  justify-content: center
  margin-bottom: 8px
  padding: 12px

  @include dark
    background: $dark
