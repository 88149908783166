@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: relative

.TimeInputGroup
  position: absolute
  right: 4px
  top: 50%
  transform: translateY(-50%)
  width: fit-content

.TimeInput
  background: $black-transparent-5
  border: 0
  font-size: 12px
  font-weight: 500
  height: 32px
  padding: 9px 8px 9px 28px
  width: 72px

  @include dark
    background: $white-transparent-5

.TimeIcon
  @include box-size(14px)
  color: $black-transparent-40

  @include dark
    color: $white-transparent-40
