.bubble {
  position: absolute;
}

.bubblePink {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 90, 189, 0.2) 0%, rgba(255, 90, 189, 0) 100%);
}

.bubbleBlue {
  background: radial-gradient(50% 50% at 50% 50%, rgba(89, 138, 255, 0.2) 0%, rgba(89, 138, 255, 0) 100%);
}

.bubble1 {
  left: -678px;
  top: -300px;
}

.bubble2 {
  left: -330px;
  top: -492px;
}

.bubble3 {
  right: -330px;
  bottom: -380px;
}

.bubble4 {
  right: 18px;
  bottom: -572px;
}
