@import '@/sass/colors'

.SlideIn
  z-index: 15
  position: fixed
  overflow: hidden
  right: 0
  left: 0
  top: 80px
  bottom: 0
  padding: 20px
  background: #eee
  transition: transform .4s ease
  transform: translateX(100%)
  &.Show
    transform: translateX(0)
    -webkit-transform: translateX(0)
  &.Light
    background-color: $white

  &.Dark
    background-color: $dark
