@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  width: 100%

.Player
  display: flex
  padding: 14px
  background-color: $black-transparent-5
  backdrop-filter: blur(2px)
  border-radius: 12px
  min-width: 230px
  min-height: 60px

  @include dark
    background-color: $white-transparent-5

.Timer
  margin-left: 12px
