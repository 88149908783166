@import '@/sass/colors'

.InfoTooltip
  cursor: pointer

  path
    transition: all 200ms linear

.Light
  path
    fill: $black-transparent-40

  &:hover
    path
      fill: $black-transparent-100

.Dark
  path
    fill: $white-transparent-40

  &:hover
    path
      fill: $white-transparent-100
