@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  position: relative

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 15px
    background: linear-gradient(90deg, rgba(0 0 0 / 5%) 0%, rgba(0 0 0 / 0%) 100%)
    opacity: 0
    transition: opacity 250ms linear

    @include dark
      background: linear-gradient(90deg, rgba(255 255 255 / 5%) 0%, rgba(255 255 255 / 0%) 100%)

.Wrapper--Scrolled-Horizontally
  &:before
    opacity: 1

.TableWrapper
  position: relative
  overflow-x: auto

.Table
  width: 100%
  border-collapse: collapse
  border-spacing: 0
  table-layout: auto
  transition: opacity 200ms linear

  tbody
    &:before
      content: "\200C"
      display: block
      line-height: 8px

.Table--LayoutFixed
  table-layout: fixed

.Thead

.Th
  position: relative
  font-size: 12px
  font-weight: 600
  line-height: 20px
  color: $black-transparent-40
  border-bottom: 1px solid $black-transparent-10
  padding: 12px 16px
  transition: color 250ms linear
  user-select: none

  @include dark
    color: $white-transparent-40
    border-color: $white-transparent-10

  @include text-truncate

.Th--Column-Is-Sorted
  color: $black-transparent-100

  @include dark
    color: $white-transparent-100

.Th--Column-Can-Sort
  cursor: pointer

  &:hover
    text-decoration: underline
    color: $black-transparent-100

  @include dark
    &:hover
      color: $white-transparent-100

.Th--Column-Fixed,
.Td--Column-Fixed
  position: sticky !important
  right: 0
  z-index: 2
  backdrop-filter: blur(12px)
  box-shadow: inset 1px 0px 0px 0px $black-transparent-10

  @include dark
    box-shadow: inset 1px 0px 0px 0px $white-transparent-10

.Th-Sort-Icon
  width: 20px

  path
    fill: $black-transparent-100

  @include dark
    path
      fill: $white-transparent-100

.Td
  position: relative
  font-weight: 400
  font-size: 12px
  line-height: 16px
  padding: 12px 16px
  word-break: break-all

.Td--Truncate
  @include text-truncate

.Tr
  transition: background-color 200ms linear

  &:hover
    td
      background-color: rgba(17, 13, 33, 0.04)

      @include dark
        background-color: rgba(255, 255, 255, 0.04)

  &:nth-child(even)
    td
      background-color: rgba(17, 13, 33, 0.02)

      @include dark
        background-color: rgba(255, 255, 255, 0.02)

    &:hover
      td
        background-color: rgba(17, 13, 33, 0.04)

        @include dark
          background-color: rgba(255, 255, 255, 0.04)

  td:first-child
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px

  td:last-child
    border-top-right-radius: 10px
    border-bottom-right-radius: 10px

.SpinnerWrapper
  position: absolute
  top: 0
  left: 0
  z-index: 4
  display: block
  width: 100%
  height: 100%
  max-height: 400px

.Spinner
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.isLoading
  opacity: 30%
  pointer-events: none
  user-select: none
