@import '@/sass/colors'
@import '@/sass/mixins'

.Tab
  position: relative
  color: $black-transparent-40
  font-size: 16px
  font-weight: 600
  margin-right: 40px
  padding: 10px 0
  outline: none
  transition: color 200ms linear

  &:hover
    color: $black-transparent-100

    .Ticker
      color: $white-transparent-100
      background-color: $black-transparent-100

  @include dark
    color: $white-transparent-40

    &:hover
      color: $white-transparent-100

      .Ticker
        color: $black-transparent-100
        background-color: $white-transparent-100

  // &::before
  //   content: ""
  //   display: block
  //   position: absolute
  //   top: 50%
  //   left: 0
  //   width: 100%
  //   height: 70%
  //   background-color: black
  //   border-radius: 8px
  //   transform: translateY(-50%)
  //   opacity: 0

.TablistWrapper
  position: relative

  &::before
    content: ''
    display: block
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 1px
    background-color: $black-transparent-10
    z-index: 2

    @include dark
      background-color: $white-transparent-10

.Panel
  outline: none

.Tablist
  position: relative

.Scrollable
  display: flex
  overflow-x: hidden
  overflow-y: hidden

.Arrow
  position: absolute
  top: calc(50% - 0.5px)
  transform: translateY(-50%)
  height: 100%
  width: 40px
  background-color: $white
  z-index: 1
  cursor: pointer
  color: $black-transparent-40
  transition: color 200ms linear

  &:hover
    color: $black-transparent-100

  @include dark
    background-color: $dark
    color: $white-transparent-40

    &:hover
      color: $white-transparent-100

  svg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.ArrowLeft
  left: 0

  &::after
    position: absolute
    top: 0
    left: 40px
    content: ""
    height: 100%
    width: 32px
    pointer-events: none
    background: linear-gradient(to right, rgba(255 255 255 / 100%) 0%, rgba(255 255 255 / 0%) 90%)

    @include dark
      background: linear-gradient(to right, rgba(17 13 33 / 100%) 0%, rgba(17 13 33 / 0%) 90%)

.ArrowRight
  right: 0

  &::before
    position: absolute
    top: 0
    right: 40px
    content: ""
    height: 100%
    width: 32px
    pointer-events: none
    background: linear-gradient(to left, rgba(255 255 255 / 100%) 0%, rgba(255 255 255 / 0%) 90%)

    @include dark
      background: linear-gradient(to left, rgba(17 13 33 / 100%) 0%, rgba(17 13 33 / 0%) 90%)

.Title
  display: inline-block
  font-size: 14px
  vertical-align: middle

.Ticker
  display: inline-block
  padding: 2px 4px
  border-radius: 4px
  font-size: 10px
  font-weight: 700
  line-height: 13px
  color: $black-transparent-20
  background-color: $black-transparent-10
  transition: color 200ms linear, background-color 200ms linear

  @include dark
    color: $white-transparent-20
    background-color: $white-transparent-10

.Tab--Selected
  color: $black

  &::after
    content: ""
    display: block
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 2px
    background-color: $black
    z-index: 3

  .Ticker
    color: $white-transparent-100
    background-color: $black-transparent-100

  @include dark
    color: $white

    .Ticker
      color: $black-transparent-100
      background-color: $white-transparent-100

    &::after
      background-color: $white
