@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  display: inline-block
  padding: 2px 6px
  border-radius: 4px
  font-weight: 500
  font-size: 14px
  line-height: 20px
  user-select: none

.Badge--Sale
  background: rgba(10, 192, 127, 0.08)
  color: #38BB8C

.Badge--Mint
  background: rgba(227, 152, 64, 0.08)
  color: #E39840

.Badge--List
  background: rgba(164, 118, 200, 0.08)
  color: #A476C8

.Badge--Delist
  background: rgba(112, 110, 122, 0.08)
  color: #A09EA6

.Badge--Transfer
  background: rgba(86, 129, 227, 0.08)
  color: #5681E3
