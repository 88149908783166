$breakpoint-sm: 576px
$breakpoint-md: 768px
$breakpoint-lg: 992px
$breakpoint-xl: 1200px
$breakpoint-xxl: 1440px
$breakpoint-xl3: 1820px
$breakpoint-xl4: 1920px // fhd
$breakpoint-xl5: 2560px // 2k
$breakpoint-xl6: 3840px // 4k

$header-height: 80px
$sidebar-width: 240px
