@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  padding: auto

.Button
  display: inline-block
  padding: 10px 12px
  border: 1px solid $black-transparent-10
  border-radius: 10px
  font-weight: 600
  font-size: 14px
  line-height: 20px
  cursor: pointer
  user-select: none
  transition: all 200ms linear

  &:hover
    border-color: $black-transparent-20

    @include dark
      border-color: $white-transparent-20

  @include dark
    border-color: $white-transparent-10

.Button--Active
  color: $white
  background-color: $black-transparent-100
  border-color: $black-transparent-100

  @include dark
    color: $black
    background-color: $white-transparent-100
    border-color: $white-transparent-100
