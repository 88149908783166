@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  display: flex
  width: 100%
  height: 100%
  padding: 0

  &.isWrap
    height: 32px
    background-color: $white-transparent-60
    border-radius: 8px
    padding: 0 12px
    transition: background-color 200ms linear
    backdrop-filter: blur(2px)

    &:hover
      background-color: $white-transparent-100

.Slider

  &:hover
    .Track
      background-color: $black-transparent-20

      @include dark
        background-color: $white-transparent-20

    .Thumb
      transform: translateY(-50%) scale(1.25)

.Track
  background-color: $black-transparent-10
  height: 8px
  border-radius: 8px
  transition: background-color 200ms linear

  @include dark
    background-color: $white-transparent-10

.FilledTrack
  background-color: $dark
  height: 8px
  border-top-left-radius: 8px
  border-bottom-left-radius: 8px

  @include dark
    background-color: $white

.Thumb
  background-color: $dark
  width: 8px
  height: 8px
  transform: translateY(-50%) scale(1)
  box-shadow: none
  border: 0

  @include dark
    background-color: $white

.isLightMode

  .Slider
    &:hover
      .Track
        background-color: $black-transparent-20

  .Track
    background-color: $black-transparent-10

  .FilledTrack
    background-color: $dark

  .Thumb
    background-color: $dark
