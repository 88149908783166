@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  width: 100%
  padding: 16px
  background-color: $black-transparent-5
  backdrop-filter: blur(2px)
  border-radius: 12px
  font-weight: 500
  font-size: 14px
  line-height: 20px

  @include dark
    background-color: $white-transparent-5

.Icon
  width: 48px

.Name
  font-weight: 500
  font-size: 14px
  line-height: 20px

  @include text-truncate
