@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  margin-top: 15px

.Text
  font-weight: 700
  line-height: 20px
  margin-bottom: 2px

.Link
  position: relative
  font-size: 12px
  font-weight: 700
  line-height: 18px
  cursor: pointer

  @include text-gradient

  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    background: $purple-gradient

.Date
  font-size: 12px
  line-height: 18px

.DaysLeft
  background-color: #A8BCFF
  color: $white
  font-size: 10px
  line-height: 18px
  border-radius: 4px
  padding: 0 5px
