@import '@/sass/colors'
@import '@/sass/mixins'

.Wrapper
  background: $white
  border: 1px solid $black-transparent-10
  border-radius: 12px
  padding: 12px

  @include dark
    border-color: transparent
    background: $white-transparent-5

  > div
    border-bottom: 1px solid $black-transparent-5
    display: flex
    justify-content: space-between
    padding: 8px 0

    @include dark
      border-color: $white-transparent-5

    &:first-child
      padding-top: 0

    &:last-child
      border-bottom: 0
      padding-bottom: 0

    p
      font-size: 14px

.Label
  color: $black-transparent-60

  @include dark
    color: $white-transparent-60

.Value
  font-weight: bold

.CopyButton
  @include box-size(20px)
  align-items: center
  border: 0
  border-radius: 6px
  color: $black-transparent-40
  display: flex
  justify-content: center
  min-width: auto
  padding: 0

  @include dark
    color: $white-transparent-40

  &:hover
    background: $black-transparent-5
    color: $black

    @include dark
      background: $white-transparent-5
      color: $white